<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <el-button type="primary" @click="showRoleDialog(null)">添加</el-button>
                </div>
            </div>
            <el-table :data="roles" class="mt-2">
                <el-table-column prop="name" label="名称" sortable />
                <el-table-column prop="level" label="级别" sortable width="100" />
                <el-table-column label="权限" width="100">
                    <template #default="scope">
                        <el-button type="text" @click="showRolePermissionsDialog(scope.row)">
                            {{scope.row.permissions.length}} 个
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="100">
                    <template #default="scope">
                        <el-button type="text" @click="showRoleDialog(scope.row)">修改</el-button>
                        <el-button type="text" class="text-danger" @click="deleteRole(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <el-dialog :visible.sync="roleDialogVisible" title="角色" :close-on-click-modal="false" width="400px">
            <el-form>
                <el-form-item label="名称" class="form-item-required">
                    <el-input v-model="role.name" placeholder="名称" />
                </el-form-item>
                <el-form-item label="级别" class="form-item-required">
                    <el-input-number v-model="role.level" :min="0" controls-position="right" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="saveRole">确定</el-button>
                <el-button @click="roleDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>

        <el-dialog :visible.sync="rolePermissionsDialogVisible" title="权限" :close-on-click-modal="false" width="400px">
            <el-form>
                <div style="margin-bottom: 10px;">
                    <div class="border rounded overflow-auto narrow-scrollbar" style="height: 400px;">
                        <el-checkbox-group v-model="role.permissions">
                            <div v-for="permissionGroup in $store.state.dataDefinitions.permissions"
                                :key="permissionGroup.name">
                                <div class="text-info text-sm bg-light font-weight-bold px-3 py-2">
                                    {{permissionGroup.name}}
                                </div>
                                <div v-for="permission in permissionGroup.permissions" :key="permission.id"
                                    class="mx-3 my-2">
                                    <el-checkbox :label="permission.id">{{permission.name}}</el-checkbox>
                                </div>
                            </div>
                        </el-checkbox-group>
                    </div>
                </div>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="updateRolePermissions">确定</el-button>
                <el-button @click="rolePermissionsDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                roles: [],
                role: {},
                roleDialogVisible: false,
                rolePermissionsDialogVisible: false,
            };
        },
        methods: {
            async loadRoles() {
                let response = await this.$axios.get('/api/Role/GetRoles');
                this.roles = response.data;
            },
            showRoleDialog(role) {
                if (role) {
                    this.role = JSON.parse(JSON.stringify(role));
                }
                else {
                    this.role = { level: 0, permissions: [] };
                }
                this.roleDialogVisible = true;
            },
            async saveRole() {
                if (this.role.id == undefined) {
                    await this.$axios.post('/api/Role/AddRole', {
                        name: this.role.name,
                        level: this.role.level,
                    });
                    this.roleDialogVisible = false;
                    this.loadRoles();
                }
                else {
                    await this.$axios.post('/api/Role/UpdateRole', {
                        id: this.role.id,
                        name: this.role.name,
                        level: this.role.level,
                    });
                    this.roleDialogVisible = false;
                    this.loadRoles();
                }
            },
            showRolePermissionsDialog(role) {
                this.role = JSON.parse(JSON.stringify(role));
                this.rolePermissionsDialogVisible = true;
            },
            async updateRolePermissions() {
                await this.$axios.post('/api/Role/UpdateRolePermissions', {
                    id: this.role.id,
                    permissions: this.role.permissions,
                });
                this.rolePermissionsDialogVisible = false;
                this.loadRoles();
            },
            async deleteRole(id) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                await this.$axios.post('/api/Role/DeleteRole', { id: id });
                this.loadRoles();
            },
        },
        created() {
            this.loadRoles();
        },
    };
</script>